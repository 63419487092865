// <span class="loader"></span>
import './loader.css'

export const Loader = () => {
    return (
        <div className="loader"></div>
    )
}


