import styled from "styled-components";
import React, {useEffect, useMemo, useState} from "react";
import {ColorButton} from "./color-button";
import {PlusIcon} from "./icons/plus";
import {
    Area,
    Boost,
    boostAreaHandler,
    claimHandler,
    demolishHandler,
    onboardingHandler,
    useArea
} from "../api/requests/area";
import {useAreasStore} from "../store/areas-store";
import {useUserStore} from "../store/user-store";
import {useBoostsStore} from "../store/boosts-store";
import {CheckedIcon} from "./icons/checked-icon";
import {getBoostIcon} from "./utils/getBoostIcon";
import {useSpring, animated} from 'react-spring';
import {ActionModal} from "./action-modal";
import {usePositionStore} from "../store/position-store";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Info} from "./info";
import {BottomNav} from "./bottom-nav";
import {AreaField} from "./area";
import {OnboardingModal} from "./views/onboarding";
import {Loader} from "./loader/loader";


export const TowerInfo = () => {
    const {position} = usePositionStore()
    const [fakeLoading, setFakeLoading] = useState(true)
    const {data: areaFromApi, mutate} = useArea({
        pos_x: position?.pos_x as number,
        pos_y: position?.pos_y as number,
        skip: !position
    })

    const nav = useNavigate()
    const [selectedBoost, setSelectedBoost] = React.useState<Boost | null>(null)
    const [isDemolishModalOpen, setIsDemolishModalOpen] = React.useState(false)
    const {setAreas} = useAreasStore()
    const {user, setUser} = useUserStore()
    const {boosts} = useBoostsStore();
    const [area, setArea] = useState<Area | null>()
    const [search] = useSearchParams()
    useEffect(() => {
        if (fakeLoading) {
            setTimeout(() => {
                setFakeLoading(false)
            }, 1000)
        }
    }, [fakeLoading])


    const [elapsedSeconds, setElapsedSeconds] = useState(area?.elapsed_rent_seconds || 0)

    useEffect(() => {
        if (areaFromApi) {
            setElapsedSeconds(areaFromApi.elapsed_rent_seconds)
        }
    }, [areaFromApi])

    useEffect(() => {
        const interval = setInterval(() => {
            setElapsedSeconds(prev => prev + 1)
        }, 500)

        return () => clearInterval(interval)
    }, [])

    const avalibleToClaim = useMemo(() => {
        return area?.rent_per_hour_amount!! * elapsedSeconds / 3600
    } , [elapsedSeconds, area?.rent_per_hour_amount])

    useEffect(() => {
        if (areaFromApi) {
            setArea(areaFromApi)
        }
    }, [areaFromApi]);


    const onClaim = async () => {
        if (!area) return
        const resp = await claimHandler({
            pos_x: area.pos_x,
            pos_y: area.pos_y
        })
        await mutate()
        setAreas((prevAreas) => {
                return prevAreas?.map((prevArea) => {
                        if (prevArea.pos_x === area.pos_x && prevArea.pos_y === area.pos_y) {

                            return resp.area
                        }
                        return prevArea
                    }
                )
            }
        )
        setArea(resp.area)
        setUser((prevUser) => {
            return {
                ...prevUser,
                second_balance: prevUser?.second_balance + resp.claimed
            }
        })

    }


    const onDemolish = async () => {
        if (!area) return
        const resp = await demolishHandler({
            pos_x: area.pos_x,
            pos_y: area.pos_y
        })
        setAreas((prevAreas) => {
                return prevAreas?.map((prevArea) => {
                        if (prevArea.pos_x === area.pos_x && prevArea.pos_y === area.pos_y) {
                            return resp
                        }
                        return prevArea
                    }
                )
            }
        )
        setIsDemolishModalOpen(false)
        nav('/')
    }


    const onBoost = async (name: string) => {
        const res = await boostAreaHandler({
            name,
            pos_x: area?.pos_x as number,
            pos_y: area?.pos_y as number
        })
        setAreas((prevAreas) => {
                return prevAreas?.map((prevArea) => {
                        if (prevArea.pos_x === area?.pos_x && prevArea.pos_y === area?.pos_y) {
                            setArea(res)
                            return res
                        }
                        return prevArea
                    }
                )
            }
        )
        setUser((prevUser) => {
            return {
                ...prevUser,
                // @ts-ignore
                second_balance: prevUser?.second_balance - area[name + '_setup_price'] as unknown as number
            }
        })
    }

    const onCloseOnboarding = async () => {
        await onboardingHandler()
        setUser((prevUser) => {
            return {
                ...prevUser,
                is_onboarding_completed: true
            }
        })
        nav('/')
    }


    if(!user) return null


    return (
        <Wrapper>
            {(!area || !user || fakeLoading) &&
                <LoadingContainer>
                <Loader/>
                </LoadingContainer>
            }
            <Container>
                <FieldContainer>
                    <Field/>
                    <SAreaField area={area as Area} id={'1'}/>
                </FieldContainer>
                <Scroll>
                    <Balance
                        rent_per_hour_amount={search.get('onboarding') === 'true' ? 1000 : area?.rent_per_hour_amount as number}
                        available_to_claim={search.get('onboarding') === 'true' ? 1000 : avalibleToClaim as number}
                        max_claim_amount={search.get('onboarding') === 'true' ? 1000 : area?.max_claim_amount as number}
                    />
                    <Row>
                        <Info title={'Boc level'} value={area?.building_level || 0}/>
                        <Info title={'Daily Rent'} value={area?.rent_per_hour_amount!! * 24 || 0}/>
                    </Row>

                    <div style={{height: '100%', width: '100%'}}>
                        <ColorButton
                            onClick={onClaim}
                            variant={'primary'}
                            text={'CLAIM'}
                        />
                    </div>
                    <List style={{marginBottom: 25}}>
                        <SubTitle>
                            Boost
                        </SubTitle>
                        {boosts?.map((boost, index) => {
                            if (boost.name === 'block') {
                                return null
                            }
                            return (
                                <BoostItem
                                    icon={getBoostIcon(boost.name)}
                                    onClick={() => setSelectedBoost(boost)}
                                    key={index}
                                    hasbuileded={area ? area['has_' + boost?.name as keyof Area] as boolean : false}
                                    channel={boost.name}
                                    value={area ? area[boost?.name + '_setup_price' as keyof Area] as number : 0}/>
                            )
                        })}
                    </List>
                    <List style={{marginBottom: 25}}>
                        <SubTitle>
                            DEMOLISH
                        </SubTitle>
                        <ColorButton
                            onClick={() => setIsDemolishModalOpen(true)}
                            variant={'secondary'}
                            text={'demolish'}
                        />
                    </List>
                </Scroll>
            </Container>
            {area &&
                <ActionModal
                    onClick={async () => {
                        if (selectedBoost && user.second_balance >= area[selectedBoost.name + '_setup_price' as keyof Area]) {
                            await onBoost(selectedBoost.name)
                            setSelectedBoost(null)
                        }
                    }}
                    variant={user.second_balance >= area[selectedBoost?.name + '_setup_price' as keyof Area] ? 'primary' : 'secondary'}
                    text={
                        user.second_balance >= area[selectedBoost?.name + '_setup_price' as keyof Area]
                            ? area[selectedBoost?.name + '_setup_price' as keyof Area] + ' BLOXX'
                            : 'Insufficient funds'
                    }
                    subText={'Please confirm that you want to setup ' + selectedBoost?.name}
                    title={selectedBoost?.name || ''}
                    open={selectedBoost !== null}
                    onClose={() => {
                        setSelectedBoost(null)
                    }}
                />
            }
            <ActionModal
                onClick={onDemolish}
                text={'demolish'}
                variant={'secondary'}
                title={'demolish the building'}
                open={isDemolishModalOpen}
                onClose={() => setIsDemolishModalOpen(false)}
            />

            <OnboardingModal
                hamster={4}
                onClick={onCloseOnboarding}
                zIndex={1000000000}
                isLast text={'Here you can build\n' +
                'towers, collect rent and\n' +
                'buy additional plots'} next={'/'}
            />
            <BottomNav/>
        </Wrapper>
    )
}


const List = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

`


interface EarnItemProps {
    channel: string;
    value: number
    hasbuileded: boolean
    onClick?: () => void
    icon?: React.ReactNode
}

const BoostItem = ({channel, value, hasbuileded, icon, onClick}: EarnItemProps) => {
    return (
        <ThreeDContainer
            onClick={!hasbuileded ? onClick : undefined}
            hasbuileded={hasbuileded}>
            <ItemRow>
                <IconContainer>
                    {icon}
                </IconContainer>
                <BoostCol>
                    <ChannelText hasbuileded={hasbuileded}>
                        {channel}
                    </ChannelText>

                    <ValueText hasbuileded={hasbuileded}>
                        {hasbuileded ? 'Max Level' : `${value} BLOXX`}
                    </ValueText>
                </BoostCol>
                {hasbuileded ? <CheckedIcon/> : <PlusIcon/>}
            </ItemRow>
        </ThreeDContainer>
    )
}

const IconContainer = styled.div`
    border-radius: 6px;
    border: 2px solid #20150E;
    background: #FFFBD6;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    height: 36px;
`


const ThreeDContainer = styled.div<{ hasbuileded: boolean }>`
    display: flex;
    height: 56px;
    padding: 8px 10px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 6px;
    border: 2px solid #20150E;
    background: ${({hasbuileded}) => !hasbuileded ? '#494D5B' : '#FFE600'};
    box-sizing: border-box;
    box-shadow: 0px 4px 8px 0px rgba(255, 255, 0, 0.50);
`


const ChannelText = styled.div<{ hasbuileded: boolean }>`
    color: ${({hasbuileded}) => !hasbuileded ? '#FFE600' : '#000'};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
    display: flex;
    gap: 4px;
`


const ValueText = styled.div<{ hasbuileded: boolean }>`
    color: ${({hasbuileded}) => !hasbuileded ? '#FFA301' : '#000'};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
    text-transform: uppercase;
`

const ItemRow = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
`

const BoostCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
    width: 80%;
`

const SubTitle = styled.div`
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 20px */
`


const Wrapper = styled.div`
    background: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(7px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 1000000;
`


const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(7px);
    background-image: url('/images/dark-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000000;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background-image: url('/images/dark-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    gap: 16px;
    padding-top: calc(54px + var(--tg-safe-area-inset-top, 0) + var(--tg-content-safe-area-inset-top, 0));
`

const Scroll = styled.div`
    overflow-y: auto;
    justify-content: space-between;
    scrollbar-width: none;
    padding-left: 16px;
    padding-right: 16px;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 200px;

`


const Row = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 16px;
`


interface ProgressBarProps {
    available_to_claim: number;
    max_claim_amount: number;
    rent_per_hour_amount: number;
}

const Balance = ({available_to_claim, max_claim_amount, rent_per_hour_amount}: ProgressBarProps) => {
    const [currentClaim, setCurrentClaim] = useState(available_to_claim);

    useEffect(() => {
        setCurrentClaim(available_to_claim || 0);
    }, [available_to_claim]);

    const animatedClaim = useSpring({
        value: currentClaim,
        from: {value: 0},
        config: {duration: 1000},
    });

    useEffect(() => {
        const incrementPerSecond = rent_per_hour_amount / 3600; // Convert hourly rate to per second

        const interval = setInterval(() => {
            setCurrentClaim(prev => {
                const newClaim = prev + incrementPerSecond;
                if (newClaim >= max_claim_amount) {
                    clearInterval(interval);
                    return max_claim_amount;
                }
                return newClaim;
            });
        }, 1000);

        return () => clearInterval(interval); // Cleanup on unmount
    }, [rent_per_hour_amount, max_claim_amount, available_to_claim]);

    return (
        <BalanceContainer>
            <Inner>
                <Label>
                    Bloxx balance
                </Label>
                <Value>
                    <animated.div>{animatedClaim.value.to((val) => val.toFixed(2))}</animated.div>
                </Value>
            </Inner>
            <Bg/>
        </BalanceContainer>
    )

}


const Inner = styled.div`
    display: flex;
    padding: 6px 50px 9px 50px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #090909;
    flex-direction: column;
    gap: 4px;
    z-index: 10;
    position: relative;
    box-sizing: border-box;

`

const BalanceContainer = styled.div`
    width: 270px;
    height: 63px;
    position: relative;
`

const Bg = styled.div`
    width: 278px;
    height: 53px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #FFA300;
    position: absolute;
    z-index: 0;
    top: 10px;
    left: -4px;
`


const Label = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
`
const Value = styled.div`
    color: #FFE600;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
`

const Field = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="166" height="75" viewBox="0 0 166 75" fill="none">
            <path
                d="M165 51.94L157.054 69.3419L127.072 74L105.036 61.268L75.0541 65.926L53.018 53.194L23.0361 57.8521L1 45.1201L8.94587 27.7181L38.9159 23.06H38.9278L46.8737 5.65805L76.8437 1L98.8917 13.732L128.862 9.07395L150.91 21.806L142.964 39.208H142.952L165 51.94Z"
                fill="#89CE1D" stroke="#26FF00" stroke-width="0.357922" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

const FieldContainer = styled.div`
    position: relative;
    scale: 1.4;
`

const SAreaField = styled(AreaField)`
    position: absolute;
    top: 12px;
`